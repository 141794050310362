import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { css, cx } from "@emotion/css";

export const Header = () => {
  const menuItems = ["about"]; // ["portfolio", "stories", "about"]
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div
      className={css`
        position: fixed;
        width: inherit;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 6rem;

        @media (max-width: 650px) {
          flex-direction: column;
          justify-content: center;
          height: 9rem;
        }
      `}
    >
      <div
        className={css`
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          @media (max-width: 650px) {
            padding-bottom: 1rem;
            align-items: center;
          }
        `}
      >
        <img
          className={css`
            height: 2.5rem;
          `}
          src="img/signature.png"
          alt=""
        />
        <div
          className={css`
            font-size: 0.8rem;
            letter-spacing: 2px;
            color: var(--dark-grey);
            margin-top: -0.25rem;
          `}
        >
          photographer & developer
        </div>
      </div>

      <div
        className={css`
          display: flex;
          font-family: Playfair Display;
          font-size: 1.55rem;
        `}
      >
        {menuItems.map((menuItem, index) => (
          <div
            key={index}
            onClick={() => navigate(`/${menuItem}`)}
            className={cx(
              css`
                margin-right: 2rem;
                transform: scale(1.1, 1);
                position: relative;

                &:last-child {
                  margin-right: 0;
                }

                &::before {
                  content: "";
                  position: absolute;
                  display: block;
                  width: 100%;
                  height: 1px;
                  bottom: -0.25rem;
                  left: 0;
                  background-color: black;
                  transition: transform 0.3s ease;
                }

                &:hover {
                  cursor: pointer;
                }

                &:hover::before {
                  transform: scaleX(1);
                }
              `,
              location.pathname.includes(menuItem)
                ? css`
                    &::before {
                      transform: scaleX(1);
                    }
                  `
                : css`
                    &::before {
                      transform: scaleX(0);
                    }
                  `
            )}
          >
            {menuItem}
          </div>
        ))}
      </div>
    </div>
  );
};
