import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { css } from "@emotion/css";
import { Header, Footer } from "./components";
import { About } from "./pages";

export const AppRouter = () => {
  return (
    <div
      className={css`
        text-align: center;
        font-family: Poppins;
        display: flex;
        justify-content: center;
      `}
    >
      <div
        className={css`
          width: 85%;
        `}
      >
        <Router>
          <Header />
          <div
            className={css`
              box-sizing: border-box;
              min-height: 100vh;
              padding-top: 6rem;
              display: flex;
              flex-direction: column;

              @media (max-width: 650px) {
                padding-top: 8rem;
              }
            `}
          >
            <div
              className={css`
                flex-grow: 1;
              `}
            >
              <Routes>
                <Route path="/about" element={<About />} />
              </Routes>
            </div>
            <Footer />
          </div>
        </Router>
      </div>
    </div>
  );
};
