import React from "react";
import { css } from "@emotion/css";

export const About = () => {
  const myAge = Math.floor(
    (new Date() - new Date(1992, 10, 3)) / (1000 * 60 * 60 * 24 * 365)
  );
  const linkStyle = css`
    color: black;
    text-decoration: none;
  `;
  const headerStyle = css`
    font-weight: 600;
  `;

  return (
    <div
      className={css`
        display: flex;
        flex-direction: row;
        margin-top: 3rem;

        @media (max-width: 1000px) {
          flex-direction: column-reverse;
          align-items: center;
        }
      `}
    >
      <div
        className={css`
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          margin-right: 3rem;
          text-align: right;
          letter-spacing: 1px;
          font-size: 1rem;

          @media (max-width: 1000px) {
            align-items: center;
            text-align: center;
            margin: 3rem 0;
          }
        `}
      >
        <div
          className={css`
            font-family: Playfair Display;
            font-size: 2rem;
            font-weight: 700;
            transform: scale(1.1, 1);
            margin-right: 0.75rem;
            color: white;
            background: linear-gradient(
                to right,
                var(--purple) 50%,
                transparent 50%
              )
              left;
            background-size: 200%;
            transition: 0.5s ease-out;

            &:hover {
              color: var(--purple);
              background-position: right;
            }
          `}
        >
          about me.
        </div>

        <br />

        <div>
          Software developer, photographer & plant lover 🪴
          <br />
          {myAge}y old. Based in Malmö, Sweden.
        </div>

        <br />

        <div>
          <div className={headerStyle}>education</div>
          Master of Science in Engineering: Computer Science, Lund 2014-2020
          <br />
          Human Rights Studies, Lund 2019
          <br />
          Psychology, Lund 2018
          <br />
          Peace and Conflict Studies, Lund 2017
          <br />
          The Photographic Image, Malmö 2013-2014
        </div>

        <br />

        <div
          className={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <div className={headerStyle}>interviews</div>
          <a
            href="https://www.sydsvenskan.se/2019-05-14/jag-kunde-inte-bara-ga-forbi"
            target="_blank"
            rel="noreferrer"
            className={linkStyle}
          >
            Hallå Lund, 2019
          </a>
          <a
            href="https://www.sydsvenskan.se/2019-05-01/tiggarnas-drommar-karleksbekymmer-och-deras-liv-som?fbclid=IwAR02b0Lcp-_rXcqnAvZeatcmmj-d2hVNA4qtGzLhGt9ghTCRumJJ2D4rc9g"
            target="_blank"
            rel="noreferrer"
            className={linkStyle}
          >
            Sydsvenskan, 2019
          </a>
          <a
            href="https://sverigesradio.se/sida/avsnitt/1232218?programid=3442&start=66&fbclid=IwAR1H2RFolaWpmZpTIfhu50Z08UzPduE58VzBLL-Y_v6R2OFMc5Wa6fFZTrA"
            target="_blank"
            rel="noreferrer"
            className={linkStyle}
          >
            P4 Malmöhus, 2019
          </a>
          <a
            href="http://vellinge.lokaltidningen.se/nyheter/2019-02-05/-De-l%C3%A4rde-k%C3%A4nna-tiggare-Vi-%C3%A4r-mer-lika-%C3%A4n-vi-tror-4089240.html?fbclid=IwAR0YPbZnqV8DsVUpjppz0UQa_CbZesEPNoQoB6FcAhSXqGO7Fk29JQnmh_o"
            target="_blank"
            rel="noreferrer"
            className={linkStyle}
          >
            Lokaltidningen, 2019
          </a>
        </div>

        <br />

        <div>
          <div className={headerStyle}>books</div>
          <a
            href="http://www.lindskogforlag.se/nar-vi-far-ordet_start.php"
            target="_blank"
            rel="noreferrer"
            className={linkStyle}
          >
            När vi får ordet, 2019
          </a>
        </div>
      </div>

      <img
        src="img/about.png"
        alt=""
        className={css`
          width: 100%;
          max-width: 25rem;
          object-fit: contain;
        `}
      />
    </div>
  );
};
