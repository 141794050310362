import React from "react";
import { css } from "@emotion/css";

export const Footer = () => {
  const thisYear = new Date().getFullYear();

  return (
    <div
      className={css`
        padding: 2rem 0;
        font-size: 0.75rem;
        font-weight: 300;
        color: var(--dark-grey);
      `}
    >
      Copyright © Cecilia Lindskog {thisYear}
    </div>
  );
};
